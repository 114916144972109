.proyectos-section {
  padding: 60px 20px;
  background: linear-gradient(to bottom, var(--color-bg), var(--color-bg-2));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.proyectos-section h2 {
  margin: 20px 0;
  text-align: center;
  font-size: 3rem;
  color: var(--hero-title-color-2);
  position: sticky;
  top: 0;
  background-color: transparent;
  z-index: 100;
}

.section-subtitle {
  text-align: center;
  color: var(--color-text);
  max-width: 1000px;
  margin: 0 auto 50px;
  line-height: 1.5;
  padding: 0 20px;
  font-size: 1.8rem;
}

.proyectos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1400px;
  width: 100%;
  padding: 20px;
}

@media (max-width: 768px) {
  .proyectos-grid {
    grid-template-columns: 1fr;
  }
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}
