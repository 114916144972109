/* Variables de color */
@import '../index.css'; 

.servicios-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding-left: 20px;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  padding-right:  20px;
}

/* Responsive */
@media (max-width: 768px) {
  .servicios-grid {
    grid-template-columns: 1fr;
    padding-left: 0px;
    padding-right: 0px;
  }
}

/* Contenedor principal de la sección de servicios */
.servicios {
  position: relative;
  padding: 60px 20px;
  background: linear-gradient(to bottom, var(--color-bg), var(--color-bg-3));
  margin: auto;
  min-height: 100vh;
  overflow: hidden;
}

/* Título de la sección */
.servicios h1 {
  margin: 20px 0; /* Espaciado fijo alrededor del título */
  text-align: center;
  font-size: 3rem; /* Ajusta el tamaño del texto según necesites */
  color: var(--hero-title-color-2);
  position: sticky; /* Fija el título en la parte superior */
  top: 0;
  background-color: transparent; /* Fondo para mantener la legibilidad */
  z-index: 100;
  text-transform: uppercase;

}

/* Contenedor de cada tarjeta de servicio */
.servicio-card {
  position: relative;
  padding: 20px;
  background: linear-gradient(to top, var(--color-bg-solid), var(--color-bg));
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  transition: all 0.3s ease;
  min-height: 350px;
  cursor: pointer;
  /* Añade espacio inferior si se necesita más separación entre tarjetas */
  margin-bottom: 30px; 
}

/* Efecto de brillo en las tarjetas del portafolio */
.servicio-card::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(to bottom right, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.2) 40%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0.2) 60%, rgba(255,255,255,0.2) 100%);
  transform: rotate(30deg);
  transition: all 0.5s;
  opacity: 0;
  pointer-events: none; /* Evitar que el efecto interfiera con la interacción */
}

.servicio-card:hover::after {
  opacity: 1;
  left: -100%;
  top: -100%;
}

/* Ajuste para escalar el ícono o imagen dentro de la tarjeta, si aplica */
.servicio-card:hover .habilidad-icono {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

/* Asegúrate de que la tarjeta de servicio tenga un overflow hidden para que el brillo no se salga */
.servicio-card {
  overflow: hidden;
}

.servicio-card.active h3 {
  color: var(--color-text);
}

.servicio-card.active h4 {
  color: var(--color-text);
}

.servicio-card.active .servicio-descripcion {
  color: var(--color-text);
}

.servicio-card.active .royecto-descripcion {
  color: var(--color-text);
}

/* Hover en la tarjeta */
.servicio-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Título del servicio */
.servicio-card h3 {
  color: var(--color-accent) !important;
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.servicio-card:hover h3 {
  transform: scale(1.02);
  color: var(--color-accent) !important;
  transition: transform 1s ease;
}

/* Descripción del servicio */
.servicio-descripcion {
  color: var(--color-secondary) !important;
  margin-bottom: 20px;
  line-height: 1.6;
}

/* Descripción del servicio */
.servicio-card:hover .servicio-descripcion {
  color: var(--color-text) !important;
  transform: scale(1.02);
  transition: transform 1s ease;
}

/* Listado de beneficios y tecnologías */
.servicio-beneficios,
.servicio-tecnologias {
  margin-top: 15px;
  margin-bottom: 15px;
}

.servicio-beneficios h4,
.servicio-tecnologias h4 {
  color: var(--color-accent) !important;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.servicio-beneficios ul,
.servicio-tecnologias ul {
  list-style-type: none;
  padding-left: 0;
  margin: 10px 0;
}

.servicio-beneficios li {
  color: var(--color-secondary);
  margin-bottom: 8px;
  padding-left: 20px;
  position: relative;
}

.servicio-beneficios li::before {
  content: "•";
  color: var(--color-accent);
  position: absolute;
  left: 0;
}

.servicio-tecnologias {
  position: relative;
  z-index: 5;
}

.servicio-tecnologias ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.servicio-tecnologias li {
  display: inline-block;
  background: var(--color-bg-2);
  color: var(--color-text);
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  z-index: 10;
  border: 1px solid transparent;
}

.servicio-tecnologias li:hover {
  background: var(--color-accent);
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-color: var(--color-accent);
}

.servicio-tecnologias li:active {
  transform: translateY(0) scale(0.98);
}

/* Contenedor de tecnologías */
.contenedor-tecnologias {
  margin-bottom: 80px;
  position: relative;
  z-index: 5;
}

/* Animación para tecnologías */
.servicio-tecnologias li {
  display: inline-block;
  background: var(--color-bg-2);
  padding: 5px 10px;
  border-radius: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  transition: all 0.3s ease;
}

.servicio-tecnologias li:hover {
  background: var(--color-accent);
  transform: translateY(-3px);
  cursor: pointer;
}

.servicio-tecnologias li:active {
  background: var(--color-accent);
  transform: translateY(-3px) scale(0.95);
  cursor: pointer;
}

/* Contenedor del botón de llamada a la acción */
.servicio-cta {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

/* Animación para fade in */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.servicio-card {
  animation: fadeInUp 0.5s ease-out;
}

/* Estilos para los botones CTA */
.cta-button-3 {
  background: linear-gradient(135deg, #a80c9b, #7bcff8, #00cc99);
  background-size: 300% 300%;
  animation: gradientBG 10s ease infinite;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
  text-decoration: none;
  font-weight: bold;
}

.cta-button-3:hover {
  background-color: var(--hero-button-hover-bg-color);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0,0,0,0.3);
  text-shadow: 0 2px 4px rgba(0,0,0,0.3), 0 1px 2px rgba(0,0,0,0.2);
  animation: gradientBG 18s ease infinite;
}

.cta-button-3:active {
  transform: scale(0.95) !important;
  background-color: #007d66;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3), inset 0 -1px 2px rgba(0, 0, 0, 0.2);
  animation: none;
}
