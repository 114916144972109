@import '../index.css'; 

.footer {
    background: none;
    padding: 20px;
    text-align: center;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-links {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-links a {
    color: var(--color-text);
    text-decoration: none;
    margin: 0 15px;
    font-weight: 500;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: var(--color-accent);
    transform: scale(1.05);
}

.footer-links a:active {
    transform: scale(0.995);
}

.footer-social {
    margin-top: 20px;
    border-top: 1px solid var(--color-secondary);
    padding-top: 10px;
}

.footer-social p {
    margin: 10px 0;
    font-size: 14px;
    color: var(--color-secondary);
}

.social-links {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.social-links a {
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.social-links a:hover {
    transform: scale(1.05);
}

.social-links a:active {
    transform: scale(0.995);
}



.social-links a[href*="linkedin"] {
    color: #0077B5;
}

.social-links a[href*="github"] {
    color: var(--color-text);
}

.social-links a[href*="wa.me"] {
    color: #25D366;
}

.social-links a:hover {
    opacity: 0.8;
}

.footer-copyright {
    margin-top: 20px;
    font-size: 14px;
    color: var(--color-secondary);
}

/* Estilos responsivos para dispositivos móviles */
@media (max-width: 666px) {
    .footer-content {
        display: grid;
        grid-template-columns: 1fr;
        text-align: center;
        gap: 20px; /* Espacio entre las secciones del footer */
    }

    .footer-links {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 2 columnas para los links */
        gap: 10px;
    }

    .footer-links a {
        margin: 10px 0;
        text-align: center;
    }

    .social-links {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* 3 columnas para los iconos de redes sociales */
        gap: 10px;
        justify-items: center;
    }
}
