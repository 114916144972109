@import '../index.css'; 

.settings-menu-container {
  position: fixed;
  top: 20px;
  right: 35px;
  z-index: 1100;
}

.settings-icon {
  width: 24px;
  height: 24px;
  color: var(--color-text);
}

.settings-icon:hover {
  width: 24px;
  height: 24px;
  color: var(--color-accent);
}


.settings-toggle {
  background: none;
  color: var(--color-text);
  border: none;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.settings-toggle:hover {
  fill: var(--color-accent);
  transform: scale(1.1);
}

.settings-toggle:active {
  transform: scale(0.95);
}

.settings-menu {
  position: absolute;
  top: 50px;
  right: -12px;
  background-color: var(--color-bg);
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--color-shadow);
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(-20px);
  pointer-events: none;
}

.settings-menu.open {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.settings-option {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s, transform 0.3s;
}

.settings-option:hover {
  background-color: var(--color-bg-2);
  color: var(--color-accent);
  transform: scale(1.1);
}

.settings-option.active {
  background-color: var(--color-accent);
  color: var(--color-bg);
  transform: scale(0.95);
}

.language-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flag-image {
  width: 24px;
  height: 24px;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  background-color: var(--color-bg);
  color: var(--color-text);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  margin-top: 5px;
}

.settings-option:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 925px) {
  .settings-menu-container {
    top: 18px;
    right: 20px;
  }
}