/* Variables de color */
@import '../index.css'; 

/* Contenedor de habilidades */
.arsenal-habilidades {
  position: relative;
  padding: 60px 20px;
  background: linear-gradient(to bottom, var(--color-bg), var(--color-bg-2));
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
}

.arsenal-habilidades h2 {
  margin: 20px 0;
  text-align: center;
  font-size: 3rem;
  color: var(--hero-title-color-2);
  position: sticky;
  top: 0;
  background-color: transparent;
  z-index: 100;
}

.titulo-seccion {
  font-size: 3rem;
  margin-top: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.introduccion {
  font-size: 1.8rem;
  text-align: center;
  color: var(--color-text);
  max-width: 1000px;
  margin: 0 auto 50px;
  line-height: 1.5;
  padding: 0 20px;
}

/* Estilo de las tarjetas de habilidad */
.habilidades-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1400px;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
}

.habilidad-card {
  background: linear-gradient(145deg, var(--color-bg-solid), var(--color-bg));
  border-radius: 15px;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 250px;
  padding: 0;
}

.habilidad-header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 30px 25px;
  border-radius: 15px 15px 0 0;
  position: relative;
  overflow: hidden;
  min-height: 120px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
}

.habilidad-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-shadow);
  z-index: 1;
}

.habilidad-nombre {
  font-size: 1.6rem;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  z-index: 2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.habilidad-header.strategic-vision { background-image: url('../../assets/images/skills/strategic_vision.png'); }
.habilidad-header.team-leadership { background-image: url('../../assets/images/skills/team_leadership.png'); }
.habilidad-header.tech-integration { background-image: url('../../assets/images/skills/tech_integration.png'); }
.habilidad-header.ai-solutions { background-image: url('../../assets/images/skills/ai_solutions.png'); }
.habilidad-header.data-analysis { background-image: url('../../assets/images/skills/data_analysis.png'); }
.habilidad-header.logistics { background-image: url('../../assets/images/skills/logistics.png'); }
.habilidad-header.ecommerce { background-image: url('../../assets/images/skills/ecommerce.png'); }

.habilidad-icono {
  display: none;
}

.habilidad-descripcion {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--color-text);
  margin-top: 8%;
  margin-bottom: 3%;
  flex-grow: 1;
  padding: 0 25px;
}

.habilidad-ejemplos {
  padding: 0 25px 25px;
  list-style-type: none;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  z-index: 1;
}

.habilidad-card.active .habilidad-ejemplos {
  opacity: 1;
  max-height: 200px;
}

.habilidad-ejemplos li {
  margin-bottom: 12px;
  position: relative;
  padding-left: 20px;
}

.habilidad-ejemplos li::before {
  content: '→';
  position: absolute;
  left: 0;
  color: var(--color-accent);
}

.habilidad-ejemplos a {
  color: var(--color-accent);
  text-decoration: none;
  transition: color 0.3s ease;
}

.habilidad-ejemplos a:hover {
  color: var(--color-accent);
  text-decoration: none;
}

.habilidad-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  background: linear-gradient(145deg, var(--color-bg-3), var(--color-bg-solid));
}

@media (max-width: 768px) {
  .habilidades-grid {
    grid-template-columns: 1fr;
    padding: 10px;
    gap: 20px;
  }

  .introduccion {
    font-size: 1.4rem;
    padding: 0 15px;
    margin-bottom: 30px;
  }

  .habilidad-card {
    padding: 0;
    min-height: auto;
  }

  .habilidad-nombre {
    font-size: 1.4rem;
  }

  .habilidad-descripcion {
    font-size: 1rem;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Animación suave para los ejemplos */
.habilidad-ejemplos.active {
  animation: fadeIn 0.5s ease forwards;
}
