.chat-button {
  position: fixed !important;
  right: 1.5rem !important;
  bottom: 1.5rem !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 30px !important;
  background-color: var(--color-accent) !important;
  color: white !important;
  border: none !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 2.3rem !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.2s ease !important;
  z-index: 1000 !important;
}

.chat-button:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.chat-modal {
  position: fixed;
  bottom: 6rem;
  right: 2rem;
  width: 380px;
  max-width: 90vw;
  height: 70vh;
  background: var(--chatbot-chat-widget-bg-color);
  border-radius: 15px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1000;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  border: 3px solid var(--chatbot-chat-widget-user-message-bg-color);
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background: var(--chatbot-chat-widget-user-message-bg-color);
  color: var(--chatbot-chat-widget-user-message-text-color);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

.chat-header-content {
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: var(--color-accent);
  font-size: 1.8rem;
}

.chat-header-title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--chatbot-chat-widget-user-message-text-color);
}

.chat-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.header-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.header-icon {
  font-size: 250%;
  color: var(--chatbot-chat-widget-user-message-text-color);
}

.header-text {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.header-text h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--chatbot-chat-widget-user-message-text-color);
}

.header-text p {
  margin: 0;
  font-size: 1.1rem;
  color: var(--chatbot-chat-widget-user-message-text-color);
  opacity: 0.9;
}

.chat-subtitle {
  font-size: 0.85rem;
  opacity: 0.9;
  margin: 0;
  line-height: 1.2;
  color: var(--chatbot-chat-widget-primary-color-text);
}

.close-button {
  background: none;
  margin-top: 0.5rem;
  border: none;
  cursor: pointer;
  color: var(--chatbot-chat-widget-user-message-text-color);
  font-size: 250%;
  transition: transform 0.2s ease;
}

.close-button:hover {
  transform: scale(1.1);
}

.chat-content {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: var(--chatbot-chat-widget-user-message-bg-color) transparent;
  background-color: var(--chatbot-chat-widget-bg-color);
}

.messages-container {
  flex: 1;
  padding: 0.75rem 1rem;
  margin: 0;
  background-color: var(--chatbot-chat-widget-bg-color);
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.message {
  padding: 0.875rem 1rem;
  border-radius: 1rem;
  max-width: 85%;
  word-wrap: break-word;
  line-height: 1.4;
  transition: transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message:hover {
  transform: translateY(-1px);
}

.message-content {
  line-height: 1.5;
  white-space: pre-line;
  font-size: 1rem;
}

.message.system {
  background-color: var(--chatbot-chat-widget-system-message-bg-color);
  color: var(--chatbot-chat-widget-system-message-text-color);
  margin-right: 3rem;
  border: 1px solid var(--color-accent);
  position: relative;
}

.message.user {
  background-color: var(--color-accent);
  color: var(--chatbot-chat-widget-user-message-text-color);
  margin-left: 3rem;
  position: relative;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.chat-bottom-container {
  background-color: var(--chatbot-chat-widget-bg-color);
  border-top: 3px solid var(--color-accent);
  margin-top: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.chat-input-container {
  display: flex;
  padding: 10px;
  gap: 10px;
  background-color: var(--chatbot-chat-widget-bg-color);
  color: var(--color-accent);
}

.chat-input {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid var(--color-accent);
  border-radius: 20px;
  font-size: 1rem;
  background: var(--color-bg-2);
  color: var(--chatbot-chat-widget-primary-color-text);
  height: 48px;
}

.chat-input::placeholder {
  color: var(--chatbot-chat-widget-primary-color-text);
  opacity: 0.7;
}

.chat-input:focus {
  outline: none;
  border-color: var(--color-accent);
  box-shadow: 0 0 0 1px var(--color-accent);
}

.send-button {
  background: var(--chatbot-chat-widget-user-message-bg-color);
  color: white;
  border: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.send-button svg {
  width: 28px;
  height: 28px;
}

.send-button:hover {
  transform: scale(1.1);
}

.message-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.message-grid {
  display: grid;
  gap: 0px;
  margin: 0;
  padding: 0;
}

.message-item {
  background: var(--color-accent);
  color: var(--chatbot-chat-widget-user-message-text-color);
  padding: 6px 10px 6px 6px;
  border-radius: 8px;
  font-size: 0.9rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  gap: 6px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  margin-bottom: 8px;
}

.message-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.message-text {
  flex: 1;
  min-width: 0;
  padding-left: 4px;
}


@media (max-width: 768px) {
  .chat-content {
    flex: 1;
    overflow-y: auto;
  }

  .chat-bottom-container {
    flex-shrink: 0;
  }

  .chat-options-icons {
    padding: 8px;
    gap: 8px;
  }

  .chat-option-icon {
    width: 36px;
    height: 36px;
    font-size: 1.2rem;
  }

  .option-tooltip {
    font-size: 0.7rem;
    padding: 3px 6px;
  }

  .message {
    max-width: 90%;
    font-size: 0.95rem;
    padding: 10px 12px;
  }
}

@media (max-width: 480px) {
  .chat-modal {
    right: 10px;
    left: 10px;
    width: auto;
    max-width: calc(100% - 20px);
    margin: 0;
    height: 70vh;
    display: flex;
    flex-direction: column;
  }

  .chat-content {
    max-height: 60%;
  }

  .chat-options-icons {
    padding: 6px;
    gap: 6px;
  }

  .chat-option-icon {
    width: 32px;
    height: 32px;
    font-size: 1.1rem;
  }
}

.redirect-message {
  background-color: var(--color-background);
  padding: 1rem;
  border-radius: 8px;
  margin: 0.5rem 0;
  animation: fadeIn 0.3s ease-in-out;
}

.redirect-message p {
  margin: 0.5rem 0;
  line-height: 1.5;
}

.redirect-message .close-instructions {
  font-size: 0.9rem;
  opacity: 0.9;
  margin-top: 0.5rem;
}

.redirect-message span {
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.redirect-message span:hover {
  opacity: 0.8;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
