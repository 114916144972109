*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: inherit;
}

.timeline-container {
  --j: calc(1 - var(--i));
  --narr: 0;
  --k: calc(1 - var(--narr));
  font: 500 1em/1.25 koho, trebuchet ms, verdana, sans-serif;
  transition: 0.3s;
  margin: 2rem auto;
  padding: 1rem;
  max-width: 100%;
  overflow: hidden;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Línea central */
.timeline::after {
  content: '';
  position: absolute;
  width: 3px;
  background: linear-gradient(180deg, var(--hero-title-color), var(--hero-title-color-2));
  top: 300px;
  bottom: 280px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 1px;
  z-index: 0;
}

.timeline article {
  position: relative;
  width: calc(50% - 40px);
  margin: 2rem 0;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
  z-index: 2;
}

/* Posicionamiento alternado */
.timeline article:nth-child(odd) {
  left: 0;
}

.timeline article:nth-child(even) {
  left: 50%;
  margin-left: 40px;
}

/* Ajuste de entrelazado a partir del segundo elemento */
.timeline article:not(:first-child) {
  margin-top: -160px;
}

/* Círculo en la línea temporal */
.timeline article::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--color-accent);
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  box-shadow: 0 0 0 6px var(--color-bg-2),
              0 0 20px var(--color-accent);
}

.timeline article:nth-child(odd)::before {
  right: -50px;
}

.timeline article:nth-child(even)::before {
  left: -50px;
}

@media (max-width: 768px) {
  .timeline::after {
    left: 30px;
    top: 260px;
    bottom: 275px;

  }

  .timeline article {
    width: calc(100% - 80px) !important;
    margin-left: 60px !important;
    padding: 0 20px 0 0 !important;
    left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 2rem;
  }

  .timeline article::before {
    left: -60px !important;
    right: auto !important;
    top: 50%;
    transform: translateY(-50%);
  }

  .timeline article .content-wrapper {
    width: 100%;
  }
}

.timeline article:hover::before {
  transform: translateY(-50%) scale(1.3);
  transition: all 0.3s ease-in-out;
}

.timeline article .content-wrapper {
  position: relative;
  z-index: 2;
  background: linear-gradient(145deg, var(--color-bg-solid), var(--color-bg));
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  min-height: 250px;
}

.timeline article .content-wrapper:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  background: linear-gradient(145deg, var(--color-bg-3), var(--color-bg-solid));
}

/* Banner de imagen */
.timeline article .banner-image {
  width: 101%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.timeline article .content-wrapper:hover .banner-image {
  transform: scale(1.05);
}

.timeline article .image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  pointer-events: none;
}

/* Header con el año */
.timeline article .year {
  position: relative;
  width: 100%;
  padding: 1rem;
  background: linear-gradient(145deg, var(--color-bg-solid), var(--color-bg));
  color: var(--color-bg);
  text-align: right;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  display: flex;
  justify-content: flex-end;
  color: var(--color-accent);
  align-items: center;
}

.timeline article .year::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.1));
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.timeline article:hover .year::before {
  transform: translateX(0);
}

.timeline article .content {
  padding: 1.5rem;
  padding-top: 0.5rem;
}

/* Contenido */
.timeline article .content {
  padding: 20px;
  transition: all 0.3s ease;
}

.timeline article h3 {
  margin: 0 0 0.5rem;
  font-size: 1.25rem;
  color: var(--color-accent);
}

.timeline article h4 {
  margin: 0 0 1rem;
  font-size: 1rem;
  color: var(--color-accent);
  font-weight: 500;
}

.timeline article p {
  margin: 0;
  color: var(--color-text);
  line-height: 1.6;
}

.timeline article .logros {
  margin-top: 1.5rem;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.timeline article .content-wrapper.expanded .logros {
  opacity: 1;
  max-height: 1000px;
  margin-bottom: 1rem;
}

.timeline article .logros h5 {
  color: var(--color-accent);
  font-size: 1.1rem;
  margin-bottom: 0.8rem;
}

.timeline article .logros ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
}

.timeline article .logros li {
  color: var(--color-text);
  margin-bottom: 0.5rem;
  padding-left: 1.2rem;
  position: relative;
  line-height: 1.4;
}

.timeline article .logros li::before {
  content: '•';
  color: var(--color-accent);
  position: absolute;
  left: 0;
  font-weight: bold;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.timeline article .proyecto-link {
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  background: linear-gradient(135deg, #a80c9b, #7bcff8, #00cc99);
  background-size: 300% 300%;
  animation: gradientBG 10s ease infinite;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 20px;
  text-decoration: none;
  font-weight: bold;
  margin-top: 1.5rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  z-index: 2;
  position: relative;
  isolation: isolate;
}

.timeline article .proyecto-link::before {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.15);
  border-radius: inherit;
  z-index: -1;
}

.timeline article .proyecto-link:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 8px rgba(0,0,0,0.3);
  text-shadow: 0 2px 4px rgba(0,0,0,0.3), 0 1px 2px rgba(0,0,0,0.2);
  animation: gradientBG 18s ease infinite;
}

.timeline article .proyecto-link:hover::before {
  background: rgba(255, 255, 255, 0.2);
}

.timeline article .proyecto-link:active {
  transform: scale(0.95);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3), inset 0 -1px 2px rgba(0, 0, 0, 0.2);
  animation: none;
  background: linear-gradient(135deg, #8a0a7f, #65acd0, #00a37a);
}

.timeline article .examples {
  padding: 0 20px 20px;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  margin-top: 10px;
  position: relative;
  z-index: 2;
  border-radius: 0 0 15px 15px;
}

.timeline article .content-wrapper.expanded .examples {
  opacity: 1;
  max-height: 1000px;
  padding-bottom: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.timeline article .examples a {
  display: inline-block;
  margin: 5px 10px;
  padding: 5px 10px;
  background: var(--color-accent);
  border-radius: 15px;
  color: var(--color-bg-solid);
  text-decoration: none;
  transition: all 0.3s ease;
  z-index: 3;
}

.timeline article .examples a:hover {
  background: var(--color-accent-hover);
  transform: translateY(-2px);
}

/* Animación de entrada */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive */
@media (max-width: 1024px) {
  .timeline article .banner-image {
    height: 200px;
  }
}

@media (max-width: 860px) {
  .timeline article .banner-image {
    height: 160px;
  }
}

@media (max-width: 768px) {


  .timeline article {
    width: calc(100% - 30px) !important;
    margin-left: 60px !important;
    padding: 0 20px 0 0 !important;
    left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 2rem;
  }

  .timeline article::before {
    left: -60px !important;
    right: auto !important;
    top: 50%;
    transform: translateY(-50%);
  }

  .timeline article .year {
    right: 0px !important;
    left: auto !important;
  }
}

@media (max-width: 600px) {
  .timeline article .banner-image {
    height: 190px;
  }
}

@media (max-width: 480px) {
  .timeline article .banner-image {
    height: 130px;
  }

  .timeline article .content {
    padding: 1rem;
  }
}

@media (max-width: 360px) {


  .timeline article .banner-image {
    height: 120px;
  }
}


@media (max-width: 12em) {
  .timeline-container {
    font-size: 0.75em;
  }
}
