.project-progress {
  position: relative;
  width: 100%;
  background: linear-gradient(to bottom, var(--color-bg-3), var(--color-bg)) !important;
  min-height: 100vh;
  overflow: hidden;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.project-content {
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

.project-header {
  text-align: left;
  margin-bottom: 2rem;
  width: 100%;
}

.project-title {
  font-size: 2rem;
  color: var(--hero-title-color);
  margin-bottom: 0.8rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: -0.02em;
  font-family: 'Work Sans', sans-serif;
  text-align: center;
}

.project-subtitle {
  color: var(--color-accent);
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.last-updated {
  font-size: 0.75rem;
  color: var(--color-text-muted);
  margin-top: 0.4rem;
  font-style: italic;
  text-align: center;
}

.section-title {
  font-size: 1.6rem;
  color: var(--hero-title-color);
  margin-bottom: 1.5rem;
  font-weight: 600;
  text-align: left;
  position: relative;
  padding-bottom: 0.4rem;
  font-family: 'Work Sans', sans-serif;
  letter-spacing: -0.02em;
  transition: color 0.3s ease;
}

.section-title:hover {
  color: var(--color-accent);
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  transform: none;
  width: 60px;
  height: 2px;
  background-color: var(--color-accent);
  border-radius: 2px;
}

.section-subtitle {
  font-size: 1rem;
  color: var(--hero-title-color);
  margin: 0.8rem 0;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.requirement-card,
.sprint-card,
.summary-card {
  position: relative;
  padding: 1.2rem;
  background: var(--color-bg-solid);
  border: 1px solid var(--color-accent);
  border-radius: 8px;
  box-shadow: 0 3px 10px var(--color-shadow);
  transition: all 0.3s ease;
  overflow: hidden;
  margin-bottom: 1.2rem;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.requirement-card:hover,
.sprint-card:hover,
.summary-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px var(--color-shadow);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
  gap: 1.5rem;
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 1.2rem;
}

.card-title {
  font-size: 1.2rem;
  color: var(--hero-title-color);
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-family: 'Work Sans', sans-serif;
}

.card-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

.card-section {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.card-section:last-child {
  margin-bottom: 0;
}

.requirements-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.requirement-card {
  background-color: var(--color-bg-alt);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  text-align: left;
}

.requirement-card .card-title {
  color: var(--color-text);
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  font-family: 'Work Sans', sans-serif;
}

.requirement-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.requirement-list li {
  position: relative;
  padding-left: 1.2rem;
  margin-bottom: 0.7rem;
  color: var(--color-text);
  font-size: 0.9rem;
  line-height: 1.4;
  text-align: left;
}

.requirement-list li:before {
  content: "•";
  position: absolute;
  left: 0.5rem;
  color: var(--color-accent);
  font-size: 1em;
}

.sprints-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.sprint-card {
  background-color: var(--color-bg-alt);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  text-align: left;
}

.sprint-title {
  color: var(--color-text);
  font-size: 1.1rem;
  margin-bottom: 0.8rem;
  font-weight: 600;
  font-family: 'Work Sans', sans-serif;
}

/* Actualizar los estilos del progress-container */
.progress-container {
  width: 100%;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.progress-indicator {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.2rem;
}

.progress-bar {
  width: 100%;
  height: 6px;
  background-color: var(--color-bg-alt);
  border-radius: 3px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background-color: var(--color-accent);
  border-radius: 3px;
  transition: width 0.5s ease;
}

.sprint-objective {
  margin-bottom: 1rem;
  color: var(--color-text);
  font-size: 0.95rem;
  line-height: 1.4;
}

.task-list {
  margin-top: 1rem;
}

.task-list-title {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--color-text);
  text-align: left;
}

.task-item {
  display: flex;
  margin-bottom: 0.5rem;
  line-height: 1.4;
  text-align: left;
  padding-left: 0;
}

.task-bullet {
  margin-right: 0.5rem;
  color: var(--color-accent);
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.task-text {
  font-size: 0.9rem;
  color: var(--color-text);
}

.task-meta {
  font-size: 0.85rem;
  color: var(--color-text-muted);
  font-style: italic;
}

.task-status-tag {
  display: inline-block;
  font-size: 0.75rem;
  padding: 0.1rem 0.4rem;
  border-radius: 2px;
  margin-left: 0.5rem;
  font-weight: 500;
}

.status-completed {
  background-color: var(--color-success);
  color: white;
}

.status-in-progress {
  background-color: var(--color-primary);
  color: white;
}

.status-pending {
  background-color: var(--color-warning);
  color: black;
}

.status-blocked {
  background-color: var(--color-danger);
  color: white;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  margin: 0.4rem 0;
  border-radius: 6px;
  box-shadow: 0 2px 6px var(--color-shadow);
  background-color: var(--color-bg-solid);
}

.data-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: var(--color-bg-solid);
  table-layout: fixed;
}

.data-table th {
  background-color: var(--color-bg-alt);
  color: var(--color-text);
  font-weight: 600;
  white-space: nowrap;
  font-size: 0.85rem;
  padding: 0.6rem 0.8rem;
}

.data-table th,
.data-table td {
  padding: 0.6rem 0.8rem;
  text-align: left;
  border-bottom: 1px solid var(--color-border);
  vertical-align: middle;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  font-size: 0.9rem;
}

.data-table.three-columns th:nth-child(1) {
  width: 50%;
}

.data-table.three-columns th:nth-child(2) {
  width: 20%;
  text-align: center;
}

.data-table.three-columns th:nth-child(3) {
  width: 30%;
}

.data-table.three-columns td:nth-child(2) {
  text-align: center;
}

.data-table.four-columns th:nth-child(1) {
  width: 40%;
}

.data-table.four-columns th:nth-child(2),
.data-table.four-columns th:nth-child(3) {
  width: 15%;
  text-align: center;
}

.data-table.four-columns th:nth-child(4) {
  width: 30%;
}

.data-table.four-columns td:nth-child(2),
.data-table.four-columns td:nth-child(3) {
  text-align: center;
}

.data-list {
  list-style: none;
  padding: 0;
  margin: 0.4rem 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.4rem;
}

.data-item {
  margin: 0;
  padding: 0.6rem;
  background-color: var(--color-bg-solid);
  border-radius: 4px;
  box-shadow: 0 1px 3px var(--color-shadow);
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 0.6rem;
  line-height: 1.3;
  font-size: 0.9rem;
  text-align: left;
  justify-content: flex-start;
}

.data-label {
  font-weight: 600;
  color: var(--color-text);
  white-space: nowrap;
}

.data-value {
  font-weight: 500;
}

.data-list.numbered {
  counter-reset: item;
  list-style-type: none;
}

.data-list.numbered .data-item {
  counter-increment: item;
  position: relative;
  padding-left: 2rem;
  grid-template-columns: 1fr;
}

.data-list.numbered .data-item::before {
  content: counter(item) ".";
  position: absolute;
  left: 0.6rem;
  color: var(--color-accent);
  font-weight: 600;
}

.data-item.achievement {
  position: relative;
  padding-left: 2rem;
  grid-template-columns: 1fr;
}

.data-item.achievement::before {
  content: "✓";
  position: absolute;
  left: 0.6rem;
  color: var(--color-success);
  font-weight: bold;
}

.data-item.critical {
  border-left: 2px solid var(--color-danger);
  padding-left: 0.8rem;
}

.tasks-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  width: 100%;
}

.task-grid-item {
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 1rem;
  align-items: center;
  padding: 0.6rem;
  background-color: var(--color-bg-alt);
  border-radius: 4px;
  border-left: 3px solid var(--color-border);
}

.task-grid-item:nth-child(odd) {
  background-color: var(--color-bg-solid);
}

.task-name-col {
  display: flex;
  flex-direction: column;
}

.task-name {
  font-weight: 500;
  color: var(--color-text);
}

.task-group {
  font-size: 0.8rem;
  color: var(--color-text-muted);
  margin-top: 0.2rem;
}

.task-priority-col, .task-status-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.task-status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.status-pending {
  background-color: #f0ad4e;
  color: #fff;
}

.status-in-progress {
  background-color: #5bc0de;
  color: #fff;
}

.status-completed {
  background-color: #5cb85c;
  color: #fff;
}

.status-blocked {
  background-color: #d9534f;
  color: #fff;
}

.priority-high {
  color: #d9534f;
}

.priority-medium {
  color: #f0ad4e;
}

.priority-low {
  color: #5cb85c;
}

.two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
}

.column {
  display: flex;
  flex-direction: column;
}

.column .section-subtitle {
  margin-bottom: 0.6rem;
}

.text-success {
  color: var(--color-success);
}

.text-warning {
  color: var(--color-warning);
}

.status-complete {
  color: var(--color-success);
}

.status-partial {
  color: var(--color-warning);
}

.status-pending {
  color: var(--color-muted);
}

.priority-high {
  color: var(--color-danger);
}

.priority-medium {
  color: var(--color-warning);
}

.priority-low {
  color: var(--color-success);
}

.summary-section {
  margin: 2rem 0 1rem;
}

.summary-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
}

.summary-card {
  display: flex;
  flex-direction: column;
  text-align: left;
}

@media (max-width: 768px) {
  .project-progress {
    padding: 30px 0;
  }

  .project-content {
    padding: 0 15px;
  }

  .project-title {
    font-size: 1.8rem;
  }

  .project-subtitle {
    font-size: 0.9rem;
  }

  .section-title {
    font-size: 1.4rem;
  }

  .card-title {
    font-size: 1.1rem;
  }

  .requirements-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .sprints-container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .progress-container {
    margin: 0.8rem 0;
    /* Eliminar flex-direction ya que está en el selector principal */
  }

  .progress-indicator {
    /* Mantener el justify-content end para consistencia con desktop */
    justify-content: flex-end;
  }

  /* Asegurar que el progress-bar mantenga sus dimensiones */
  .progress-bar {
    min-height: 6px;
    width: 100%;
  }

  .requirement-card,
  .sprint-card,
  .summary-card {
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .card-header {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }

  /* Ensure progress bar and fill take full width on mobile */
  .progress-bar {
    width: 100%; /* Take full width of the container */
  }

  .progress-fill {
    display: block; /* Ensure it's a block-level element */
    width: 100%;
  }

  .summary-container {
    grid-template-columns: 1fr;
  }
}
