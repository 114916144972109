/* Variables de color */
@import '../index.css'; 

/* Contenedor principal del portafolio */
.portafolio {
  position: relative;
  padding: 60px 20px;
  background: linear-gradient(to bottom, var(--color-bg-2), var(--color-bg));
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
}

/* Título del portafolio */
.portafolio h1 {
  margin: 20px 0;
  text-align: center;
  font-size: 3rem;
  color: var(--hero-title-color-2);
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 1rem;
}

.portafolio h2 {
  margin: 20px 0;
  text-align: center;
  font-size: 3rem;
  color: var(--hero-title-color-2);
  position: sticky;
  top: 0;
  background-color: transparent;
  z-index: 100;
}

.introduccion p {
  font-size: 1.8rem;
  text-align: center;
  color: var(--color-text);
  max-width: 1000px;
  line-height: 1.5;
  padding: 0 20px;
  margin: 0 auto !important;
}

/* Grid de proyectos */
.proyectos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 30px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

/* Grid específico para proyectos personales */
.proyectos-personales {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
  gap: 30px;
  justify-content: center;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

.proyectos-grid .project-card, .proyectos-personales .project-card {
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
}

/* Aseguramos que tanto .proyecto-card como .project-card tengan el mismo ancho */
.proyecto-card,
.project-card {
  width: 100%;
  height: 100%;
  min-height: 400px;
}

/* Tarjeta de proyecto */
.proyecto-card {
  background: linear-gradient(145deg, var(--color-bg-solid), var(--color-bg));
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.proyecto-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  background: linear-gradient(145deg, var(--color-bg-3), var(--color-bg-solid));
}

.proyecto-card h3 {
  font-size: 2rem;
  color: var(--color-text);
  margin-bottom: 1rem;
  transition: color 0.3s ease;
}

.proyecto-rol {
  font-size: 1.2rem;
  color: var(--color-accent);
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.proyecto-descripcion {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--color-text);
  margin-bottom: 1.5rem;
  flex-grow: 1;
}

.proyecto-logros {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
}

.proyecto-logros li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.8rem;
  color: var(--color-text);
}

.proyecto-logros li::before {
  content: '→';
  position: absolute;
  left: 0;
  color: var(--color-accent);
}

.proyecto-tecnologias {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: auto;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.tecnologia {
  background: rgba(var(--color-accent-rgb), 0.1);
  color: var(--color-accent);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  cursor: pointer;
}

.tecnologia:hover {
  background: rgba(var(--color-accent-rgb), 0.2);
  transform: translateY(-2px);
}

.proyecto-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--color-accent);
  text-decoration: none;
  margin-top: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.proyecto-link:hover {
  transform: translateX(5px);
}

/* Responsive */
@media (max-width: 768px) {
  .proyectos-grid {
    grid-template-columns: 1fr;
    padding: 10px;
    gap: 20px;
  }

  .introduccion {
    font-size: 1.4rem;
    padding: 0 15px;
    margin: 0 !important;
  }

  .proyectos-personales {
    grid-template-columns: 1fr;
    padding: 10px;
    gap: 20px;
  }

  .proyecto-card {
    padding: 20px;
    min-height: auto;
  }

  .contenido-colapsable {
    padding: 1.5rem;
    margin: 1rem 10px;
  }

  .proyecto-card h3 {
    font-size: 1.8rem;
  }

  .proyecto-rol {
    font-size: 1.1rem;
  }

  .proyecto-descripcion {
    font-size: 1rem;
  }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}
