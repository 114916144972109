.project-card {
  background: linear-gradient(145deg, var(--color-bg-solid), var(--color-bg));
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  height: 100%;
  min-height: 250px;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.project-banner {
  width: 100%;
  height: 120px;
  overflow: hidden;
  position: relative;
}

.project-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-shadow);
  z-index: 1;
  opacity: 0.3;
}

.project-card:hover .project-banner img {
  transform: scale(1.05);
}

.project-content {
  padding: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.project-main-content {
  flex: 1;
  cursor: pointer;
  position: relative;
}

.project-header {
  margin-bottom: 1rem;
}

.project-header h3 {
  margin: 0;
  color: var(--hero-title-color-2);
  font-size: 1.6rem;
  letter-spacing: 1px;
}

.project-header h4 {
  margin: 0.5rem 0 0;
  color: var(--color-accent);
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.8;
}

.project-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 15px;
}

.tag {
  background: var(--color-accent);
  color: var(--color-text);
  padding: 6px 14px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.tag:hover {
  background: var(--color-accent-hover);
  transform: translateY(-2px);
}

.project-description {
  color: var(--color-text);
  line-height: 1.6;
  margin-bottom: 25px;
  font-size: 1.1rem;
}

.project-features {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  opacity: 0;
}

.project-features.show {
  max-height: 500px;
  opacity: 1;
  margin-bottom: 30px;
  transition: max-height 0.5s ease-in, opacity 0.3s ease-in;
}

.project-features h4 {
  color: var(--hero-title-color-2);
  margin-bottom: 15px;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

.project-features ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.project-features li {
  margin-bottom: 10px;
  color: var(--color-text);
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 1.5rem;
  position: relative;
}

.project-features li::before {
  content: "•";
  position: absolute;
  left: 0.5rem;
  color: var(--color-accent);
}

.project-links {
  margin-top: auto;
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 2;
}

.github-link {
  --link-color: var(--color-text);
  text-decoration: none;
  background: var(--color-accent);
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: var(--link-color);
  transition: transform 0.3s ease, box-shadow 0.3s ease, --link-color 0.3s ease;
}

.github-icon {
  font-size: 1.4rem;
  color: var(--link-color);
}

.github-link:hover {
  --link-color: var(--color-bg);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.project-content.expanded .expand-indicator {
  transform: rotate(180deg);
}
