.chat-options-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0.8rem;
  background-color: var(--chatbot-chat-widget-bg-color);
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  position: relative;
}

.chat-option-button {
  width: 3.5rem;
  height: 3.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem;
  background-color: var(--chatbot-chat-widget-system-message-bg-color);
  cursor: pointer;
  transition: all 0.2s ease;
  min-height: 36px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 4px;
}

.chat-option-button:hover {
  background-color: var(--color-accent);
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.chat-option-button.blue {
  color: #3b82f6;
}

.chat-option-button.blue:hover {
  background-color: #3b82f6;
  color: white;
}

.chat-option-button.blue .option-tooltip {
  background-color: #3b82f6;
  color: white;
}

.chat-option-button.cyan {
  color: #06b6d4;
}

.chat-option-button.cyan:hover {
  background-color: #06b6d4;
  color: white;
}

.chat-option-button.cyan .option-tooltip {
  background-color: #06b6d4;
  color: white;
}

.chat-option-button.yellow {
  color: #facc15;
}

.chat-option-button.yellow:hover {
  background-color: #facc15;
  color: #1f2937;
}

.chat-option-button.yellow .option-tooltip {
  background-color: #facc15;
  color: #1f2937;
}

.chat-option-button.orange {
  color: #f97316;
}

.chat-option-button.orange:hover {
  background-color: #f97316;
  color: white;
}

.chat-option-button.orange .option-tooltip {
  background-color: #f97316;
  color: white;
}

.chat-option-button.purple {
  color: #8b5cf6;
}

.chat-option-button.purple:hover {
  background-color: #8b5cf6;
  color: white;
}

.chat-option-button.purple .option-tooltip {
  background-color: #8b5cf6;
  color: white;
}

.button-icon {
  font-size: 2.2rem;
  width: 2.2rem;
  height: 2.2rem;
}

.option-tooltip {
  --tooltip-offset: 8px;
  position: absolute;
  bottom: calc(100% + var(--tooltip-offset));
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 10px;
  font-size: 1rem;
  font-weight: 500;
  color: inherit;
  border-radius: 6px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  pointer-events: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10000;
}

.chat-option-button:first-child .option-tooltip {
  transform: translateX(0%);
  left: 0;
}

.chat-option-button:last-child .option-tooltip {
  transform: translateX(-100%);
  left: 100%;
}

.chat-option-button:hover .option-tooltip {
  opacity: 1;
  visibility: visible;
}

.option-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: transparent transparent transparent;
}
