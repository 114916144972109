.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modal-content {
  background: var(--color-bg);
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  animation: modalFadeIn 0.3s ease-out;
  z-index: 1001;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-accent);
}

.modal-header h2 {
  margin: 0;
  color: var(--color-accent);
  font-size: 1.8rem;
}

.modal-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--color-text);
  padding: 5px;
  transition: all 0.3s ease;
  z-index: 1002;
}

.modal-close:hover {
  color: var(--color-accent);
  transform: scale(1.1);
}

.modal-body {
  color: var(--color-text);
  position: relative;
  z-index: 1001;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media queries para dispositivos móviles */
@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    margin: 10px;
    max-height: 85vh;
  }

  .modal-header h2 {
    font-size: 1.5rem;
  }
}
