.typing-container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.typing-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.typing-text {
  font-size: 0.95rem;
  font-weight: 500;
  margin-bottom: 4px;
}

.typing-indicator {
  width: 60px;
  height: 20px;
  position: relative;
  z-index: 4;
}

.typing-circle {
  width: 6px;
  height: 6px;
  position: absolute;
  border-radius: 50%;
  background-color: var(--color-accent);
  left: 15%;
  transform-origin: 50%;
  animation: typing-circle7124 0.5s alternate infinite ease;
}

@keyframes typing-circle7124 {
  0% {
    top: 15px;
    height: 4px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 6px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.typing-circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}

.typing-circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.typing-shadow {
  width: 4px;
  height: 3px;
  border-radius: 50%;
  background-color: currentColor;
  position: absolute;
  top: 20px;
  transform-origin: 50%;
  z-index: 3;
  left: 15%;
  filter: blur(1px);
  animation: typing-shadow046 0.5s alternate infinite ease;
  opacity: 0.3;
}

@keyframes typing-shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: 0.3;
  }

  100% {
    transform: scaleX(0.2);
    opacity: 0.2;
  }
}

.typing-shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}

.typing-shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
