.floating-button {
  position: fixed !important;
  left: 1.5rem !important;
  bottom: 1.5rem !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 30px !important;
  background-color: var(--color-accent) !important;
  color: white !important;
  border: none !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 2.3rem !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.2s ease !important;
  z-index: 1000 !important;
}

[data-theme="dark"] .floating-button {
  background-color: var(--color-accent-dark);
  color: var(--color-bg-dark);
  box-shadow: 0 2px 10px var(--color-shadow-dark);
}

.floating-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px var(--color-shadow-light);
}

[data-theme="dark"] .floating-button:hover {
  box-shadow: 0 4px 15px var(--color-shadow-dark);
}

.floating-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 10px var(--color-shadow-light);
}

[data-theme="dark"] .floating-button:active {
  box-shadow: 0 2px 10px var(--color-shadow-dark);
}

@media (max-width: 768px) {
  .floating-button {
    bottom: 1.5rem;
    left: 1.5rem;
    width: 3rem;
    height: 3rem;
  }
}
