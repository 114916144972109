/* SobreMi.css */
@import '../index.css'; 

/* Estilos generales para el contenedor */
.sobre-mi {
  position: relative;
  padding: 60px 20px; /* Añadido padding para más espacio arriba y abajo */
  background: linear-gradient(to bottom, var(--color-bg-3), var(--color-bg)); /* Color del fondo del contenedor */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Alinea desde la parte superior */
  min-height: 100vh; /* Altura mínima de la pantalla */
  overflow: hidden; /* Evita que el contenido se desborde */
}

.sobre-mi h2 {
  margin: 20px 0;
  text-align: center;
  font-size: 3rem;
  color: var(--hero-title-color-2);
  position: sticky;
  top: 0;
  background-color: transparent;
  z-index: 100;
}



.contenido-sobre-mi {
  position: relative;
  width: 100%;
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding-bottom: 40px; /* Agrega espacio adicional al final del contenedor */
}

/* Ajustes para el subtítulo */
.sub-title-container {
  font-size: 1.8rem; /* Tamaño de la fuente del subtítulo */
  font-weight: bold;
  color: var(--color-accent); /* Color del subtítulo */
  padding: 0;
  margin: 2rem 0;
  text-align: center;
  width: 100%;
  position: relative;
}

.sub-title-container::before {
  content: '“';
  font-size: 3rem;
  position: absolute;
  left: -1.5rem;
  top: -0.5rem;
  color: var(--color-accent);
}

.sub-title-container::after {
  content: '“';
  font-size: 3rem;
  position: absolute;
  right: -1.5rem;
  bottom: -1.5rem;
  color: var(--color-accent);
}


/* Estilos de las pestañas */
.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto 1rem; /* Reducido de 4rem a 2rem en el margen inferior */
  position: sticky;
  top: 60px;
  z-index: 100;
  background: rgba(var(--color-bg-rgb), 0.1);
  backdrop-filter: blur(8px);
  padding: 0.8rem;
  border-radius: 50px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 95%;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.tab-button {
  background: transparent;
  border: none;
  color: var(--color-text);
  font-size: 1.1rem;
  padding: 0.8rem 1.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  border-radius: 25px;
  font-weight: 500;
  letter-spacing: 0.5px;
  opacity: 0.7;
}

.tab-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, var(--color-accent), transparent);
  border-radius: 25px;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.tab-button:hover {
  color: var(--color-text);
  opacity: 1;
  transform: translateY(-1px);
}

.tab-button:hover::before {
  opacity: 0.15;
}

.tab-button.active {
  color: var(--color-text);
  background: rgba(var(--color-accent-rgb), 0.15);
  opacity: 1;
  font-weight: 600;
  box-shadow: 0 2px 10px rgba(var(--color-accent-rgb), 0.2);
}

.tab-button.active::before {
  opacity: 0.2;
}



.section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem 2rem; /* Reducido el padding superior de 2rem a 1rem */
  gap: 2rem;
}

.content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  gap: 2rem;
}



.image-container {
  flex: 0 1 400px;
  margin: 0 auto;
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.image-container:hover img {
  transform: scale(1.02);
}

.text-content {
  flex: 1;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.sub-title-container {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--color-accent);
  text-align: center;
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 2rem;
  position: relative;
}

/* Contenedor para los botones CTA */
.cta-seccion-sobre-mi {
  display: flex;
  gap: 10px;
  justify-content: center;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto 20px auto;
  padding: 0 20px;
}

/* Responsividad */




.shinyy {
  background: linear-gradient(to right, var(--hero-title-color-2) 0%, var(--hero-title-color) 50%, var(--hero-title-color-2) 100%);
  background-size: 200% auto;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: shine 3s linear infinite;
}

@keyframes shine {
  to { background-position: 200% center; }
}

/* Estilos para los botones CTA */
.cta-button-2 {
  background: linear-gradient(135deg, #a80c9b, #7bcff8, #00cc99);
  background-size: 300% 300%;
  animation: gradientBG 10s ease infinite;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
  text-decoration: none;
  font-weight: bold;
}

.cta-button-2:hover {
  background-color: var(--hero-button-hover-bg-color);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0,0,0,0.3);
  text-shadow: 0 2px 4px rgba(0,0,0,0.3), 0 1px 2px rgba(0,0,0,0.2);
  animation: gradientBG 18s ease infinite;
}

.cta-button-2:active {
  transform: scale(0.95);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3), inset 0 -1px 2px rgba(0, 0, 0, 0.2);
  animation: none;
}


.nav-arrow {
  background: none;
  border: none;
  font-size: 40px; /* Tamaño del símbolo de la flecha */
  color: var(--color-text);
  cursor: pointer;
  transition: color 0.3s, transform 0.2s, text-shadow 0.3s;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4); /* Sombra de texto para el efecto flotante */
}

.nav-arrow:hover {
  color: var(--color-accent);
}

.nav-arrow:active {
  transform: scale(0.9); /* Efecto de clic */
}

.nav-arrow.left {
  margin-left: -20px; /* Espaciado hacia el borde izquierdo */
  transform: rotate(0deg);
  margin-top: 3px;
}

.nav-arrow.right {
  margin-right: -20px; /* Espaciado hacia el borde derecho */
}

/* Estilos para el slider automático */
.section-content {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  max-width: 1400px;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  min-height: 500px;
  overflow: hidden;
}

.section-content.hidden {
  opacity: 0;
  transform: translateX(-100%);
}

.section-content.visible {
  opacity: 1;
  transform: translateX(0%);
}

/* Indicadores de bolitas para la navegación */
.horizontal-scroll-indicator {
  position: absolute;
  margin-top: 20px; 
  bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 10px;
}

.indicator {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--color-secondary);
  margin: 0 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.indicator.active-indicator {
  background-color: var(--color-text);
}
.indicator:hover {
  background-color: var(--color-accent);
}


@media (min-width: 768px) {
  .image-container {
    grid-template-columns: minmax(300px, 1fr) 2fr;
    align-items: start;
  }
  .content-container {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 3rem;
  }
}


@media (max-width: 666px) {
  .sub-title-container {
    text-align: center;
    font-size: 1.5rem; /* Tamaño de la fuente del subtítulo */
    font-weight: bold;
    color: var(--color-accent); /* Color del subtítulo */
    padding-left:5px;
    padding-right:0px;
    position: relative;
    margin-bottom: 1rem;
  }
  
  .text-content {
    text-align: center;
    font-size: 1rem;
  }

  .gancho-emocional {
    padding-left: 0;
  }

  .gancho-emocional::before {
    left: 50%;
    transform: translateX(-50%);
  }
  .cta-seccion-sobre-mi {
    margin-left: -10px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .tabs {
    border-radius: 20px;
    padding: 0.5rem;
  }

  .tab-button {
    font-size: 0.85rem;
    padding: 0.5rem 1rem;
    border-radius: 15px;
  }
}
